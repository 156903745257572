<template>
  <v-card style="height: 350px">
    <e-chart
      v-if="loaded"
      :option="opts"
      :autoresize="true"
      :theme="darkmode ? 'sensoronlinedark' : 'macarons'"
      style="width: 100%; max-height: 100%; padding-top: 1rem"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DatapointsWeek",

  data() {
    return {
      loaded: false,
      opts: {
        title: {
          text: this.$t("common.staticinformation.datapointsLastWeek"),
          left: "center",
        },
        xAxis: {
          type: "category",
          data: [],
        },

        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "bar",
            showBackground: true,
            label: {
              show: true,
              position: "inside",
              color: "#FFFFFF",
            },
          },
        ],
      },
    };
  },

  methods: {
    ...mapActions("tagData", ["getDatapointsLastWeek"]),
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),
  },

  async created() {
    let data = await this.getDatapointsLastWeek();
    if (data != undefined) {
      data.forEach((d) => {
        this.opts.xAxis.data.push(d.label);
        this.opts.series[0].data.push(d.value);
      });
    }
    this.loaded = true;
  },
};
</script>