<template>
  <v-card style="height: 350px">
    <e-chart
      v-if="loaded"
      :option="opts"
      style="width: 100%; max-height: 90%; padding-top: 1rem"
      :autoresize="true"
      :theme="this.darkmode ? 'sensoronlinedark' : 'macarons'"
    />
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SensorPie",

  props: ["tags", "inactiveSensors"],

  data() {
    return {
      loaded: false,
      opts: {
        title: {
          text: this.$t("common.sensorStatus"),
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "horizontal",
          left: "center",
          bottom: "bottom",
        },
        series: [
          {
            name: "Sensors",
            type: "pie",
            radius: "50%",
            data: [
              {
                value:
                  this.tags != undefined
                    ? parseInt(this.tags.length) -
                      parseInt(this.inactiveSensors)
                    : 0,
                name:
                  this.$t("common.activeSensors") +
                  " " +
                  (parseInt(this.tags.length) - parseInt(this.inactiveSensors)),
                itemStyle: {
                  color: "#62B58F",
                },
                label: {
                  color: "#62B58F",
                },
              },
              {
                value:
                  this.tags != undefined ? parseInt(this.inactiveSensors) : 0,
                name:
                  this.$t("common.inactiveSensors") +
                  " " +
                  parseInt(this.inactiveSensors),
                itemStyle: {
                  color: "#F2726F",
                },
                label: {
                  color: "#F2726F",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadow: false,
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0)",
              },
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),
  },

  methods: {},

  mounted() {
    this.loaded = true;
  },

  watch: {},
};
</script>