<template>
  <v-card>
    <v-card-title>
      {{ $t("dashboard.public.title") }}
      <v-spacer></v-spacer>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-2"
              fab
              small
              color="accent"
              v-on="on"
              @click="dialog = true"
              v-if="permitted('PublicDashboard.Create')"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("dashboard.public.create") }}</span>
        </v-tooltip>
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="publicDashboards"
        class="elevation-1 fill-height"
      >
        <template v-slot:[`item.expiresAt`]="{ item }">
          {{ dateFormat(item.expiresAt) }}
        </template>

        <template v-slot:[`item.host.name`]="{ item }">
          {{ item.host.firstname + " " + item.host.lastname }}
        </template>

        <template v-slot:[`item.link`]="{ item }">
          <v-chip color="success" outlined @click="copyToClipboard(item)">
            <v-icon dark>mdi-content-copy</v-icon>
          </v-chip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            dark
            color="error"
            :disabled="!permitted('PublicDashboard.Delete')"
            @click="deleteConfirm(item)"
            >mdi-delete</v-icon
          >
        </template>

        <template v-slot:[`item.expired`]="{ item }">
          <v-chip :color="getExpiredColor(item.expired)" outlined>
            <v-icon v-if="item.expired" dark>mdi-close</v-icon>
            <v-icon dark v-else>mdi-check</v-icon>
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="dialog">
      <PublicDashboardCreate />
    </v-dialog>

    <v-dialog v-model="confirmDelete" max-width="450">
      <v-card>
        <v-card-title class="headline">
          {{ $t("common.verify") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary lighten-1"
            text
            @click="confirmDelete = false"
            >{{ $t("common.close") }}</v-btn
          >

          <v-btn color="primary" text @click="deletePDashboard">{{
            $t("common.yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDashboardURL" max-width="670">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t("dashboard.public.URL") }}
          </span>
        </v-card-title>

        <v-card-text>
          <p class="url-container-style">{{ dialogURL }}</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showDashboardURL = false">{{
            $t("common.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PublicDashboardCreate from "@/components/common/PublicDashboardCreate";
import { format } from "date-fns";

export default {
  name: "PublicDashboardList",
  data() {
    return {
      dialog: false,
      confirmDelete: false,
      pDashboard: null,
      dialogURL: "",
      showDashboardURL: false,
      headers: [
        {
          text: this.$t("dashboard.public.fields.dashboardName"),
          value: "dashboard.name",
        },
        {
          text: this.$t("dashboard.public.fields.expiresAt"),
          value: "expiresAt",
        },
        {
          text: this.$t("dashboard.public.fields.host"),
          value: "host.name",
        },
        { text: this.$t("dashboard.public.fields.expired"), value: "expired" },
        { text: this.$t("dashboard.public.fields.copyAction"), value: "link" },
        { text: this.$t("dashboard.public.remove"), value: "action" },
      ],
    };
  },

  methods: {
    ...mapActions("dashboards", [
      "getPublicDashboards",
      "deletePublicDashboard",
    ]),
    ...mapActions("alert", ["success", "error"]),

    getExpiredColor(expired) {
      return expired ? "error" : "success";
    },

    async copyToClipboard(item) {
      var url = `${window.location.origin}/#/public/${item.publicDashboardId}`;
      let success = false;
      try {
        await navigator.clipboard.writeText(url).then(
          function () {
            success = true;
          },
          function () {
            success = false;
          }
        );
      } catch {
        success = false;
      }

      if (success) this.success(this.$t("dashboard.public.copiedSuccessful"));
      else {
        this.dialogURL = url;
        this.showDashboardURL = true;
      }
    },

    deleteConfirm(item) {
      this.pDashboard = item;
      this.confirmDelete = true;
    },

    deletePDashboard() {
      if (
        this.pDashboard != null &&
        this.pDashboard.publicDashboardId != undefined
      ) {
        this.deletePublicDashboard(this.pDashboard.publicDashboardId);
        this.pDashboard = null;
        this.confirmDelete = false;
      }
    },

    dateFormat(date) {
      return format(new Date(date), "yyyy-MM-dd");
    },
  },

  computed: {
    ...mapState("dashboards", ["publicDashboards", "publicDashboard"]),
  },

  async created() {
    await this.getPublicDashboards();
  },

  components: {
    PublicDashboardCreate,
  },
};
</script>
<style>
.url-container-style {
  background: rgba(0, 0, 0, 0.062);
  border-radius: 10px;
  border: 1px solid black;
  padding: 3rem;
}
</style>