<template>
  <v-form
    :lazy-validation="false"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-card-title>
        {{ $t("dashboard.public.createNew") }}
        <v-spacer></v-spacer>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2"
                fab
                small
                color="accent"
                router
                to="/dashboards"
                v-on="on"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("dashboard.name") }}</span>
          </v-tooltip>
        </div>
      </v-card-title>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :label="$t('dashboard.name')"
          v-model="dashboardId"
          :items="dashboards"
          item-text="name"
          item-value="dashboardId"
          prepend-icon="mdi-calendar-today"
          required
        />
      </v-card-text>

      <v-card-text>
        <v-date-picker v-model="expiresAt"></v-date-picker>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" :disabeld="!valid" x-large>{{
          $t("common.add")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PublicDashboardList",
  data() {
    return {
      valid: false,
      dashboardId: null,
      expiresAt: new Date().toISOString().substr(0, 10),
    };
  },

  methods: {
    ...mapActions("dashboards", [
      "createPublicDashboard",
      "getPublicDashboards",
      "getDashboards",
    ]),

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      var pDashboard = {
        dashboardId: this.dashboardId,
        expiresAt: this.expiresAt,
      };

      await this.createPublicDashboard(pDashboard);

      this.$router.go();
    },
  },

  computed: {
    ...mapState("dashboards", [
      "publicDashboards",
      "publicDashboard",
      "dashboards",
    ]),
  },

  async created() {
    await this.getDashboards();
  },
};
</script>