var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.templates,"items-per-page":25},scopedSlots:_vm._u([{key:`item.type`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t("templateDashboard.dashboardType." + item.type)))])]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.createdAt))+" ")]}},{key:`item.actions`,fn:function({ item }){return [(_vm.permitted('Dashboard.Update'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(
            `/template-dashboard/update/${item.templateDashboardId}`
          )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pen")])],1):_vm._e(),(_vm.permitted('Dashboard.Delete'))?_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
            _vm.selectedTemplate = item.templateDashboardId;
            _vm.confirmDelete = true;
          }}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)}),_c('delete-dialog',{attrs:{"dialog":_vm.confirmDelete},on:{"confirm-delete":_vm.removeTemplateDashboard,"cancel-dialog":() => {
        _vm.selectedTemplate = undefined;
        _vm.confirmDelete = false;
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }