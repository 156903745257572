<template>
  <v-row class="pa-2">
    <v-col cols="12" md="6" sm="6">
      <SensorPie
        :tags="tags"
        :inactiveSensors="inactiveSensorsCount"
        v-if="inactiveSensorsCount != undefined"
      />
    </v-col>
    <v-col cols="12" md="6" sm="6">
      <DatapointsWeek />
    </v-col>
    <v-col cols="12" md="4" sm="4">
      <v-card>
        <v-card-title
          ><h3 style="text-align: center; width: 100%">
            {{ $t("common.staticinformation.activeAlarms") }}
          </h3></v-card-title
        >
        <v-card-text
          ><h2>{{ activeAlarmsCount }}</h2></v-card-text
        >
      </v-card>
    </v-col>

    <v-col cols="12" md="4" sm="4">
      <v-card>
        <v-card-title
          ><h3 style="text-align: center; width: 100%">
            {{ $t("common.staticinformation.datapoints") }}
          </h3></v-card-title
        >
        <v-card-text
          ><h2>{{ dataPointsCount }}</h2></v-card-text
        >
      </v-card>
    </v-col>

    <v-col cols="12" md="4" sm="4">
      <v-card>
        <v-card-title
          ><h3 style="text-align: center; width: 100%">
            {{ $t("common.staticinformation.inactiveSensors") }}
          </h3></v-card-title
        >
        <v-card-text
          ><h2>
            {{ inactiveSensorsCount == undefined ? 0 : inactiveSensorsCount }}
          </h2></v-card-text
        >
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SensorPie from "@/components/chart/SensorPie";
import DatapointsWeek from "@/components/chart/DatapointsWeek";

export default {
  name: "StaticDashboardStatus",
  data() {
    return {
      dataPointsCount: 0,
      inactiveSensorsCount: undefined,
      activeAlarmsCount: 0,
    };
  },

  methods: {
    ...mapActions("tagData", { getDatapoints: "getDatapointsLastDay" }),
    ...mapActions("tag", {
      getTags: "getTagsWithInformation",
    }),

    ...mapActions("alarms", ["getActiveAlarms"]),

    async getInactiveSensors() {
      await this.getTags();
      let count = 0;
      for (let i = 0; i < this.tags.length; i++) {
        if (
          !this.tags[i].lastContact ||
          !this.timeWithinHours(this.tags[i].lastContact, 24)
        )
          count++;
      }

      return count;
    },

    getActiveAlarmsCount() {
      this.getActiveAlarms();
      return this.alarms.length ? this.alarms.length : 0;
    },
  },

  computed: {
    ...mapState("tag", { tags: "tagsWithInformation" }),
    ...mapState("alarms", { alarms: "activeAlarms" }),
    ...mapState("logs", ["datapoints"]),
  },

  async created() {
    this.dataPointsCount = await this.getDatapoints();
    this.inactiveSensorsCount = await this.getInactiveSensors();
    this.activeAlarmsCount = this.getActiveAlarmsCount();
  },

  components: {
    SensorPie,
    DatapointsWeek,
  },
};
</script>