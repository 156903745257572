var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{attrs:{"align":"center"}},[(_vm.chooseDashboard)?_c('StaticDashboardStatus'):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.canAccessFeature(_vm.FEATURES.MAP_DASHBOARD) &&
        _vm.permitted('Dashboard.View')
          ? 6
          : 12,"sm":"12"}},[_c('v-card',{staticClass:"mt-2 ml-2",staticStyle:{"height":"98%"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("dashboard.navigationTitle")))]),_c('v-card-text',[_c('DashboardCategoryViewer',{staticStyle:{"max-height":"100%","overflow-y":"scroll","height":"100%"}})],1)],1)],1),(
        _vm.canAccessFeature(_vm.FEATURES.MAP_DASHBOARD) &&
        _vm.permitted('Dashboard.View')
      )?_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('div',[_c('v-card',{staticClass:"mt-2 mr-2 pb-2"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("templateDashboard.title"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){return _vm.$router.push('/template-dashboard/create')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card-text',[_c('template-dashboard-list')],1)],1)],1)]):_vm._e()],1),(_vm.permitted('PublicDashboard.View'))?_c('PublicDashboardList',{staticClass:"mt-4 mx-2"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }