<template>
  <div>
    <v-data-table :headers="headers" :items="templates" :items-per-page="25">
      <template v-slot:[`item.type`]="{ item }">
        <v-chip color="primary">{{
          $t("templateDashboard.dashboardType." + item.type)
        }}</v-chip>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="permitted('Dashboard.Update')"
          icon
          @click="
            $router.push(
              `/template-dashboard/update/${item.templateDashboardId}`
            )
          "
          ><v-icon color="primary">mdi-pen</v-icon></v-btn
        >
        <v-btn
          v-if="permitted('Dashboard.Delete')"
          icon
          @click="
            () => {
              selectedTemplate = item.templateDashboardId;
              confirmDelete = true;
            }
          "
          ><v-icon color="error">mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="removeTemplateDashboard"
      v-on:cancel-dialog="
        () => {
          selectedTemplate = undefined;
          confirmDelete = false;
        }
      "
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DeleteDialog from "@/components/common/DeleteDialog";

export default {
  name: "TemplateDashboardList",

  components: { DeleteDialog },

  data() {
    return {
      confirmDelete: false,
      selectedTemplate: undefined,
      templates: [],
      headers: [
        { text: this.$t("templateDashboard.fields.name"), value: "name" },
        {
          text: this.$t("templateDashboard.fields.dashboardType"),
          value: "type",
        },
        {
          text: this.$t("templateDashboard.fields.createdAt"),
          value: "createdAt",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {},

  methods: {
    ...mapActions("templateDashboard", [
      "getTemplateDashboards",
      "deleteTemplateDashboard",
    ]),
    ...mapActions("alert", ["success", "error"]),

    async removeTemplateDashboard() {
      if (this.selectedTemplate == undefined) {
        this.confirmDelete = false;
        return;
      }

      let result = await this.deleteTemplateDashboard(this.selectedTemplate);
      if (result) this.success(this.$t("templateDashboard.deleteSuccess"));
      else this.error(this.$t("templateDashboard.deleteError"));
      this.confirmDelete = false;
      this.selectedTemplate = undefined;
      this.templates = await this.getTemplateDashboards(false);
    },
  },

  async created() {
    this.templates = await this.getTemplateDashboards(false);
  },
};
</script>